import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { logout } from "../../../Store";

import Button from "../../UI/Button/Button";
import classes from "./Footer.module.scss";

const Footer = ({ page, handleCheckInDetails }) => {
    const { isLoggedIn, isLoading } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const logoutHandler = () => {
        dispatch(logout());
    };
    return (
        <footer className={classes.footer}>
            {page === "product" ? (
                <button onClick={handleCheckInDetails}>Check in details</button>
            ) : !isLoggedIn ? (
                <>
                    <Link to="/signup" className={classes.outlined}>
                        Create an account
                    </Link>
                    <Link to="/login">Login</Link>
                </>
            ) : page === "videoChat" ? (
                <p className={classes.calling}>Calling...</p>
            ) : (
                <Button loading={isLoading} onClick={logoutHandler}>Logout</Button>
            )}
        </footer>
    );
};

export default Footer;
