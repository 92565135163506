import Services from "../Services/Services";
import {orgImageTypes} from "./orgImageTypes";

const isDev = process.env.NODE_ENV === "development";

const baseUrl =
    process.env.REACT_APP_BACKEND_BASE_URL || "https://api.dev.meetusvr.com";

const full360Url =
    process.env.REACT_APP_360_BASE_URL ||
    "https://www.dev.meetusvr.com/files/shop360/pannellum/v3/index.html";

const get360Url = ({ shopId, productId }) => {
    const shop = shopId ? `&shop_id=${shopId}` : "";
    const product = productId ? `&product_id=${productId}` : "";
    return `${full360Url}?autoLoad=true${shop}${product}`;
};

const defaultChatSettings = (orgData) => {
    return {
        mode: "light",
        color: orgData.theme?.settings?.colors?.primary || "#000",
        title: `Explore ${orgData?.name}`,
        welcoming: "Hi, what can I help you with?",
        images: [],
    };
};

function hexToRgb(hex, alpha) {
    hex = hex.replace("#", "");
    const r = parseInt(
        hex.length === 3 ? hex.slice(0, 1).repeat(2) : hex.slice(0, 2),
        16
    );
    const g = parseInt(
        hex.length === 3 ? hex.slice(1, 2).repeat(2) : hex.slice(2, 4),
        16
    );
    const b = parseInt(
        hex.length === 3 ? hex.slice(2, 3).repeat(2) : hex.slice(4, 6),
        16
    );
    if (alpha) {
        return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
        return r + ", " + g + ", " + b;
    }
}

export const getDataHandling = async ({ orgId, preview, dashboardToken }) => {
    const [{ data: orgData }, { data: settingsData }, { data: shopsData }] =
        await Promise.all([
            Services.getOrganization({ orgId: orgId }),
            Services.getChatSettings({
                orgId: orgId,
                type: preview ? "get-unpublished" : "get-published",
                dashboardToken,
            }),
            Services.getShops(orgId),
        ]);

    let chatSettingsData = JSON.parse(settingsData?.value);

    if (!chatSettingsData) {
        chatSettingsData = defaultChatSettings(orgData);
    }

    const shopHas360 = shopsData?.content?.find((item) => {
        if (item?.has_360) return item;
        else return null;
    });

    return { orgData, chatSettingsData, shopsData, shopHas360 };
};

export const handleChatImages = ({ chatSettingsData, orgData }) => {
    chatSettingsData?.images?.forEach((img) => {
        if (orgImageTypes[img?.type]) {
            if (orgImageTypes[img?.type] === orgImageTypes["332"]) {
                chatSettingsData.images[orgImageTypes[img?.type]] = [
                    ...(chatSettingsData.images[orgImageTypes[img?.type]] || []),
                    img?.image_url,
                ];
            } else {
                chatSettingsData.images[orgImageTypes[img?.type]] = img?.image_url;
            }
        }
    });

    if (!chatSettingsData?.images) {
        chatSettingsData.images = [];
    }

    /**
     * Convert orgData.images array into a dictionary of filtered image types.
     *
     * @param {Object} orgData - The organization data object.
     * @returns {Object} orgImagesDict - A dictionary of image URLs, where the keys are the image types.
     */
    const orgImagesDict = orgData?.images
        ?.filter((im) => orgImageTypes.hasOwnProperty(im?.type))
        ?.reduce((dict, im) => {
            return { ...dict, [im.type]: im.url };
        }, {});

    chatSettingsData.images["logo_url"] =
        chatSettingsData.images["logo_url"] || orgImagesDict?.[1];

    chatSettingsData.images["chat_services_widget_background"] =
        chatSettingsData.images["chat_services_widget_background"] ||
        orgImagesDict?.[301];

    return chatSettingsData;
};

export const getOriginUrlAndOrgId = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const orgId = urlParams.get("orgId");
    const preview = urlParams.get("preview");
    const url = urlParams.get("url") || "*";
    const originUrl = url.substring(0, url.length);
    const dashboardToken = urlParams.get("dashboardToken");
    return {
        orgId,
        originUrl,
        preview: preview === "true",
        dashboardToken,
    };
};

export const postMessage = (message) => {
    window.parent.postMessage(JSON.stringify(message), "*");
};

export const isArabicText = (text) => /[\u0600-\u06FF]/.test(text);

export const getImageByType = (imageList, type) =>
    imageList?.find((image) => image?.type === type)?.image_url;

const imageExtensions = [".png", ".jpg", ".svg"];
const modelExtensions = [".gltf", ".glb"];

export const imageUrls = (urls) =>
  urls?.filter((url) =>
    imageExtensions.some((extension) => url.endsWith(extension))
  );

export const modelUrls = (urls) =>
  urls?.filter((url) =>
    modelExtensions.some((extension) => url.endsWith(extension))
  );

const Utils = {
    set360Cookies(userToken) {
        const cookieData = {
            backend_api_url: baseUrl,
            files_api_url: isDev ? `${baseUrl}/files` : "/files",
        };
        if (userToken) {
            cookieData.user_token = userToken;
        }
        document.cookie = `360=${JSON.stringify(cookieData)}; path=/; domain=${
            window.location.host
        };`;
    },
    getProper360Url(shopId) {
        return get360Url({ shopId });
    },
    getProperProduct360Url(shopId, productId) {
        return get360Url({ shopId, productId });
    },
    getProperImageUrl(imagePath) {
        return `${baseUrl}/files/${imagePath}`;
    },
    getProductPrices(product) {
        const { price, discount } = product;
        const sale = Number(price - price * ((discount || 0) / 100));
        return { sale, original: price };
    },
    isNewProduct(creationDate) {
        return new Date().getTime() === new Date(creationDate).getTime();
    },
    setAppColors(themeSettings) {
        const { colors = {} } = themeSettings;
        Object.entries(colors || {}).forEach(([color, value]) => {
            document.documentElement.style.setProperty(
                "--" + color.replace("_", "-") + "-color", // eg: --primary-color
                value
            );
            document.documentElement.style.setProperty(
                "--" + color.replace("_", "-") + "-color-rgb", // eg: --primary-color-rgb
                hexToRgb(value)
            );
        });
    },
    setWidgetColors(color) {
        document.documentElement.style.setProperty("--primary-color", color);
        document.documentElement.style.setProperty(
            "--primary-color-rgb",
            hexToRgb(color)
        );
    },
};

export default Utils;
