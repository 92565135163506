import React, { useEffect, useReducer, useRef } from "react";
import { useGetEventsQuery } from "../../Store/Services/events";

import { Link } from "react-router-dom";

import PageContainer from "../../Components/Global/PageContainer/PageContainer";
import Stripe from "../../Components/Global/Stripe/Stripe";
import MetaverseContainer from "../../Components/Metaverse/MetaverseContainer/MetaverseContainer";
import Tab from "../../Components/Products/TabsContainer/Tab/Tab";
import Spinner from "../../Components/UI/Spinner/Spinner";
import classes from "./Metaverse.module.scss";

const tabs = [
  {
    id: 1,
    text: "Events active",
  },
  {
    id: 2,
    text: "Previous events",
  },
];

const limit = 20;
const todayDate = new Date().toISOString().slice(0, 19);
const yesterdayDate = new Date(Date.now() - 86400000).toISOString().slice(0, 19);

const initialState = {
  activeEvents: [],
  prevEvents: [],

  startActiveEvents: 0,
  startPrevEvents: 0,

  countActiveEvents: limit,
  countPrevEvents: limit,

  activeTotalCount: 0,
  prevTotalCount: 0,

  active: 1,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_STATE":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const Metaverse = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const activeEventsRef = useRef(null);
  const prevEventsRef = useRef(null);

  const { isLoggedIn, preview, orgId } = props;

  const {
    activeEvents,
    prevEvents,
    startActiveEvents,
    startPrevEvents,
    countActiveEvents,
    countPrevEvents,
    activeTotalCount,
    prevTotalCount,
    active,
  } = state;

  const { data: activeEventsData, isLoading: loadingActiveEvents } = useGetEventsQuery({
    start: startActiveEvents,
    count: countActiveEvents,
    orgId,
    fromDate: todayDate,
  });

  const { data: prevEventsData, isLoading: loadingPrevEvents } = useGetEventsQuery({
    start: startPrevEvents,
    count: countPrevEvents,
    orgId,
    fromDate: "2000-01-01T15:41:00",
    toDate: yesterdayDate,
  });

  useEffect(() => {
    if (activeEventsData) {
      dispatch({
        type: "SET_STATE",
        payload: {
          activeTotalCount: activeEventsData?.totalElements,
          activeEvents: [...activeEvents, ...activeEventsData?.content],
        },
      });
    }
  }, [activeEventsData]);

  useEffect(() => {
    if (prevEventsData) {
      dispatch({
        type: "SET_STATE",
        payload: {
            prevTotalCount: prevEventsData?.totalElements,
            prevEvents: [...prevEvents, ...prevEventsData?.content],
        },
      });
    }
  }, [prevEventsData]);

  const onScrollActiveEvents = () => {
    if (activeEventsRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = activeEventsRef.current;
      if (Math.ceil(scrollTop) + clientHeight >= scrollHeight) {
        if (activeTotalCount > startActiveEvents + countActiveEvents) {
          dispatch({
            type: "SET_STATE",
            payload: {
              ...state,
              startActiveEvents: startActiveEvents + countActiveEvents,
              countActiveEvents: countActiveEvents,
            },
          });
        }
      }
    }
  };

  const onScrollPrevEvents = () => {
    if (prevEventsRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = prevEventsRef.current;
      if (Math.ceil(scrollTop) + clientHeight >= scrollHeight) {
        if (prevTotalCount > startPrevEvents + countPrevEvents) {
          dispatch({
            type: "SET_STATE",
            payload: {
              ...state,
              startPrevEvents: startPrevEvents + countPrevEvents,
              countPrevEvents: countPrevEvents,
            },
          });
        }
      }
    }
  };

  return (
    <PageContainer backArrow text="Metaverse Events">
      <section className={`${classes.tabsContainer} ${preview !== true && !isLoggedIn ? classes.noMargin : ""}`}>
        <section className={classes.tabs}>
          {tabs.map((item) => (
            <Tab
              key={item.id}
              handleTab={() =>
                dispatch({ type: "SET_STATE", payload: { active: item?.id } })
              }
              item={item}
              active={active}
            />
          ))}
        </section>
      </section>
      {preview !== true && !isLoggedIn && (
        <Stripe
          text={
            <>
              You must login to join events, <Link to="/login">Login now</Link>
            </>
          }
        />
      )}

      {active === 1 && (
        <>
          {loadingActiveEvents ? (
            <Spinner fullHeight />
          ) : (
            <MetaverseContainer
              list={activeEvents}
              isLoggedIn={isLoggedIn}
              ref={activeEventsRef}
              onScroll={onScrollActiveEvents}
            />
          )}
        </>
      )}

      {active === 2 && (
        <>
          {loadingPrevEvents ? (
            <Spinner fullHeight />
          ) : (
            <MetaverseContainer
              list={prevEvents}
              isLoggedIn={isLoggedIn}
              ref={prevEventsRef}
              onScroll={onScrollPrevEvents}
              greyImage
            />
          )}
        </>
      )}

    </PageContainer>
  );
};

export default Metaverse;
