import { useHistory } from "react-router-dom";

import Tooltip from "../../Tooltip/Tooltip";
import ArrowBack from "../../Icons/ArrowBack";
import Close from "../../Icons/Close";

import classes from "./Header.module.scss";

const Header = ({ text, onCloseModal, backArrow }) => {
  const history = useHistory();

  return (
    <header className={classes.header}>
      <div className={classes.arrowAndTextContainer}>
        <button onClick={history.goBack}>
          {backArrow && (
            <Tooltip direction="bottom" content="Back">
              <ArrowBack />
            </Tooltip>
          )}
        </button>
        <p>{text}</p>
      </div>
      <button onClick={onCloseModal}>
        <Tooltip direction="bottom" content="Close">
          <Close />
        </Tooltip>
      </button>
    </header>
  );
};

export default Header;
