import React, { useEffect } from "react";
import * as yup from "yup";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import Button from "../../Components/UI/Button/Button";
import FormGroup from "../../Components/UI/FormGroup/FormGroup";
import FormControl from "../../Components/UI/FormControl/FormControl";
import PageContainer from "../../Components/Global/PageContainer/PageContainer";
import AuthLabelAction from "../../Components/UI/AuthLabelAction/AuthLabelAction";
import AuthContainer from "../../Components/UI/AuthContainer/AuthContainer";
import ErrorMessage from "../../Components/UI/ErrorMessage/ErrorMessage";
import { getErrorMessage } from "../../Utils/errorMessage";
import { login, clearError } from "../../Store";
import Lock from "../../Components/Icons/Lock";
import Sms from "../../Components/Icons/Sms";
import classes from "./Login.module.scss";

const formSchema = yup.object().shape({
    email: yup
        .string()
        .required("This field is required")
        .email("Please enter a valid email address"),
    password: yup
        .string()
        .required("This field is required")
        .min(6, "Password must be at 6 character long"),
});

const Login = ({ org, history, location }) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({ resolver: yupResolver(formSchema) });

    const fromRoute = location.state?.from?.pathname;

    const { isLoading, isLoggedIn, error } = useSelector((state) => state.auth);

    const dispatch = useDispatch();

    const onSubmitLogin = (data) => {
        const userData = {
            orgId: org.id,
            email: data.email,
            password: data.password,
            isEmployee: false,
            remember_me: true
        };
        dispatch(login(userData));
    };

    useEffect(() => {
        if (isLoggedIn) {
            if (fromRoute) {
                history.replace(fromRoute);
            } else {
                history.replace("/");
            }
        }

        return () => {
            dispatch(clearError());
        };
    }, [isLoggedIn, fromRoute, history, dispatch]);

    return (
        <PageContainer
            text="Login"
            backArrow
            page="auth-main"
            mainHeaderText={`Welcome to ${org?.name}`}
            subHeaderText="Login into your account"
        >
            <AuthContainer>
                <FormGroup onSubmit={handleSubmit(onSubmitLogin)}>
                    <FormControl
                        icon={<Sms />}
                        placeholder="Enter your email"
                        errorMessage={errors.email?.message}
                        register={register("email")}
                        inputMode="email"
                        label="Email address"
                    />
                    <FormControl
                        icon={<Lock />}
                        type="password"
                        placeholder="Enter your password"
                        errorMessage={errors.password?.message}
                        register={register("password")}
                        label="Password"
                    />

                    {error && (
                        <ErrorMessage center>{getErrorMessage(error.error)}</ErrorMessage>
                    )}

                    <div className={classes.forgetPasswod}>
                        <Link to="/forget-password">Forget password?</Link>
                    </div>

                    <Button loading={isLoading}>Login</Button>
                </FormGroup>

                <AuthLabelAction route="signup">Create an account</AuthLabelAction>
            </AuthContainer>
        </PageContainer>
    );
};

export default Login;
