import React from "react";

import EmptyProductsIcon from "../../Icons/EmptyProducts";

import classes from "./EmptyProducts.module.scss";

const EmptyProducts = ({ emptyProductsSize }) => {
  return (
    <div className={classes.wrapper}>
      <EmptyProductsIcon width={emptyProductsSize} height={emptyProductsSize} />
    </div>
  );
};

export default EmptyProducts;
