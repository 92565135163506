import { useEffect, useReducer, useRef } from "react";
import { useGetProductsQuery } from "../../Store/Services/products";

import Spinner from "../../Components/UI/Spinner/Spinner";
import ProductsList from "../../Components/Products/ProductsList/ProductsList";
import PageContainer from "../../Components/Global/PageContainer/PageContainer";
import Tab from "../../Components/Products/TabsContainer/Tab/Tab";

import classes from "./Products.module.scss";

const tabs = [
  {
    id: 1,
    text: "Discounts",
  },
  {
    id: 2,
    text: "Promotions",
  },
];

const limit = 20;

const initialState = {
  discountsProducts: [],
  promotionsProducts: [],

  startDiscounts: 0,
  startPromotions: 0,

  countDiscounts: limit,
  countPromotions: limit,

  totalDiscounts: 0,
  totalPromotions: 0,

  active: 1,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_STATE":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const Products = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const discountsRef = useRef(null);
  const promotionsRef = useRef(null);

  const { orgId, currency } = props;
  const {
    active,
    discountsProducts,
    promotionsProducts,
    countDiscounts,
    totalDiscounts,
    startDiscounts,
    countPromotions,
    totalPromotions,
    startPromotions,
  } = state;

  const { data: discountsData, isLoading: isLoadingDiscounts } =
    useGetProductsQuery({
      start: startDiscounts,
      count: countDiscounts,
      orgId: orgId,
      category_name: "Discounts",
    });

  const { data: promotionsData, isLoading: isLoadingPromotions } =
    useGetProductsQuery({
      start: startPromotions,
      count: countPromotions,
      orgId: orgId,
      has_promotions: true,
    });

  useEffect(() => {
    if (discountsData) {
      dispatch({
        type: "SET_STATE",
        payload: {
          totalDiscounts: discountsData?.total,
          discountsProducts: [...discountsProducts, ...discountsData.products],
        },
      });
    }
  }, [discountsData]);

  useEffect(() => {
    if (promotionsData) {
      dispatch({
        type: "SET_STATE",
        payload: {
          totalPromotions: promotionsData?.total,
          promotionsProducts: [
            ...promotionsProducts,
            ...promotionsData.products,
          ],
        },
      });
    }
  }, [promotionsData]);

  const onScrollDiscountsProducts = () => {
    if (discountsRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = discountsRef.current;
      if (Math.ceil(scrollTop) + clientHeight === scrollHeight) {
        if (totalDiscounts > countDiscounts) {
          dispatch({
            type: "SET_STATE",
            payload: {
              ...state,
              startDiscounts: startDiscounts + countDiscounts,
              countDiscounts: countDiscounts + limit,
            },
          });
        }
      }
    }
  };

  const onScrollPromotionsProducts = () => {
    if (promotionsRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = promotionsRef.current;
      if (Math.ceil(scrollTop) + clientHeight === scrollHeight) {
        if (totalPromotions > countPromotions) {
          dispatch({
            type: "SET_STATE",
            payload: {
              ...state,
              startPromotions: startPromotions + countPromotions,
              countPromotions: countPromotions + limit,
            },
          });
        }
      }
    }
  };

  return (
    <PageContainer text="Products" backArrow>
      <section className={classes.tabsContainer}>
        <section className={classes.tabs}>
          {tabs.map((item) => (
            <Tab
              key={item.id}
              handleTab={() =>
                dispatch({ type: "SET_STATE", payload: { active: item?.id } })
              }
              item={item}
              active={active}
              number={item.id === 1 ? totalDiscounts : totalPromotions}
            />
          ))}
        </section>
      </section>
      <div className={classes.wrapper}>
        {active === 1 ? (
          isLoadingDiscounts ? (
            <Spinner fullHeight />
          ) : (
            <ProductsList
              list={state?.discountsProducts}
              ref={discountsRef}
              onScroll={onScrollDiscountsProducts}
              currency={currency}
            />
          )
        ) : isLoadingPromotions ? (
          <Spinner fullHeight />
        ) : (
          <ProductsList
            list={state?.promotionsProducts}
            ref={promotionsRef}
            onScroll={onScrollPromotionsProducts}
            currency={currency}
          />
        )}
      </div>
    </PageContainer>
  );
};

export default Products;
