import React from "react";
import { useLocation } from "react-router-dom";

import classes from "./AppBase.module.scss";

const AppBase = ({ children, app, open, chatSettings }) => {
    const { pathname } = useLocation();
    return (
        <div
            className={`${classes.base} ${!open ? classes.baseSm : ""} ${
                !chatSettings?.withMessage && pathname === "/"
                    ? classes.withoutMessage
                    : ""
            }`}
        >
            <div className={classes.baseInner}>{app}</div>
            {children}
        </div>
    );
};

export default AppBase;
