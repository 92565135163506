import { createApi } from "@reduxjs/toolkit/query/react";

import { axiosBaseQuery } from "./index";

import ApiUrlConstants from "../../Services/ApiConstants";

export const eventsApi = createApi({
  reducerPath: "eventsApi",
  baseQuery: axiosBaseQuery(),

  endpoints: (builder) => ({
    getEvents: builder.query({
      query({ start, count, orgId, fromDate, toDate }) {
        const params = new URLSearchParams();
        params.append("start", start);
        params.append("count", count);
        params.append("orgId", orgId);
        if (fromDate) {
            params.append("fromDate", fromDate);
        }
        if (toDate) {
            params.append("toDate", toDate);
        }

        return { url: ApiUrlConstants.events, params };
      },
    }),
    getEvent: builder.query({
      query({ eventId }) {
        return { url: `${ApiUrlConstants.event}/${eventId}` };
      },
    }),
    getEventInterests: builder.query({
      query({ eventId }) {
        return { url: `${ApiUrlConstants.eventInterests}/${eventId}`, params: { count: 100 } };
      },
    }),
    eventInterest: builder.mutation({
      query({ eventId }) {
        return {
          url: `${ApiUrlConstants.eventInterest}/${eventId}`,
          method: "POST",
        };
      },
    }),
  }),
});

export const {
  useGetEventsQuery,
  useGetEventQuery,
  useGetEventInterestsQuery,
  useEventInterestMutation,
} = eventsApi;
