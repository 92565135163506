import React from "react";

import ProductCard from "../ProductCard/ProductCard";
import EmptyProducts from "../EmptyProducts/EmptyProducts";

import classes from "./ProductsList.module.scss";

const ProductsList = React.forwardRef(
  (
    { list = [], onScroll, horizontal = false, currency, emptyProductsSize },
    ref
  ) => {
    return (
      <div
        ref={ref}
        onScroll={onScroll}
        className={`${
          horizontal ? classes.listHorizontalWrapper : classes.listWrapper
        } ${!list.length ? classes.listHorizontalWrapper : ""}`}
      >
        {!list.length ? (
          <EmptyProducts emptyProductsSize={emptyProductsSize} />
        ) : (
          list.map((product) => (
            <ProductCard
              key={product.id}
              data={product}
              currency={currency}
              isSm={horizontal}
            />
          ))
        )}
      </div>
    );
  }
);

export default ProductsList;
