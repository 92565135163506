export const orgImageTypes = {
  1: "logo_url",
  301: "first_slider_images_urls",
  331: "chat_services_widget_background",
  332: "chat_services_customer_service",
  // 11: "dark_logo_url",
  // 30: "favicon",
  // 50: "seo_default",
  // 91: "product_default",
  // 101: "banner_top",
  // 111: "banner_lower",
  // 121: "banner_featured",
  // 201: "first_section_image_url",
  // 202: "second_section_image_url",
  // 203: "third_section_image_url",
  // 204: "fourth_section_image_url",
  // 300: "slider_header",
  // 302: "second_slider_images_urls",
  // 303: "third_slider_images_urls",
  // 304: "fourth_slider_images_urls",
  // 321: "first_mobile_slider_images_urls",
  // 322: "second_mobile_slider_images_urls",
  // 323: "third_mobile_slider_images_urls",
  // 324: "fourth_mobile_slider_images_urls",
};
