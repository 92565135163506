import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import AvatarGroup from "../../Chat/AvatarGroup/AvatarGroup";
import ContentAuthHeader from "./ContentAuthHeader/ContentAuthHeader";

import Tooltip from "../../Tooltip/Tooltip";
import ArrowBack from "../../Icons/ArrowBack";
import Close from "../../Icons/Close";

import Utils from "../../../Utils/utils";

import Services from "../../../Services/Services";

import classes from "./AuthHeader.module.scss";

const AuthHeader = ({
    text,
    onCloseModal,
    backArrow,
    imageUrl,
    logoPath,
    page,
    chatMode,
    mainHeaderText,
    subHeaderText,
    orgId,
    orgName,
    agentsOnline,
}) => {
    const history = useHistory();

    const headerContent = () => {
        if (page === "chat") {
            if (chatMode === "human") {
                if (agentsOnline?.length > 0) {
                    return (
                        <div className={classes.bottom}>
                            <AvatarGroup
                                users={agentsOnline}
                                count={3}
                            />
                            <div className={classes.text}>
                                <h4>{mainHeaderText}</h4>
                                <p>{subHeaderText}</p>
                            </div>
                        </div>
                    );
                } else {
                    return (
                        <ContentAuthHeader
                            mainHeaderText={mainHeaderText}
                            subHeaderText={subHeaderText}
                            logoPath={logoPath}
                            page={page}
                        />
                    );
                }
            } else {
                return (
                    <ContentAuthHeader
                        mainHeaderText={mainHeaderText || `Get a quick help via ${orgName} bot.`}
                        subHeaderText={subHeaderText || "Tell it what you need, It always loves to help."}
                        logoPath={logoPath}
                        page={page}
                    />
                );
            }
        } else {
            return (
                <ContentAuthHeader
                    mainHeaderText={mainHeaderText}
                    subHeaderText={subHeaderText}
                    logoPath={logoPath}
                    page={page}
                />
            );
        }
    };
    return (
        <header className={classes.header}>
            <div className={classes.orgCover}>
                <img src={Utils.getProperImageUrl(imageUrl)} alt={text} />
            </div>
            <div className={classes.top}>
                <div className={classes.arrowAndTextContainer}>
                    <button onClick={history.goBack}>
                        {backArrow && (
                            <Tooltip direction="bottom" content="Back">
                                <ArrowBack />
                            </Tooltip>
                        )}
                    </button>
                    <p>{text}</p>
                </div>
                <button onClick={onCloseModal}>
                    <Tooltip direction="bottom" content="Close">
                        <Close />
                    </Tooltip>
                </button>
            </div>
            {headerContent()}
        </header>
    );
};

export default AuthHeader;
