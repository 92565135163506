import React, { useEffect } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";

import Button from "../../Components/UI/Button/Button";
import FormGroup from "../../Components/UI/FormGroup/FormGroup";
import FormControl from "../../Components/UI/FormControl/FormControl";
import PageContainer from "../../Components/Global/PageContainer/PageContainer";
import AuthLabelAction from "../../Components/UI/AuthLabelAction/AuthLabelAction";
import AuthContainer from "../../Components/UI/AuthContainer/AuthContainer";
import ErrorMessage from "../../Components/UI/ErrorMessage/ErrorMessage";
import { register as registerUser, clearError } from "../../Store";
import { getErrorMessage } from "../../Utils/errorMessage";
import Lock from "../../Components/Icons/Lock";
import User from "../../Components/Icons/User";
import Sms from "../../Components/Icons/Sms";
import Phone from "../../Components/Icons/Phone";

const formSchema = yup.object().shape({
  name: yup.string().required("This field is required"),
  email: yup
    .string()
    .required("This field is required")
    .email("Please enter a valid email address"),
  phoneNumber: yup.string().required("This field is required"),
  password: yup
    .string()
    .required("This field is required")
    .min(6, "Password must be at 6 character long"),
  confirmPassword: yup
    .string()
    .required("This field is required")
    .oneOf([yup.ref("password"), null], "Passwords don't match"),
});

const SignUp = ({ org, history }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(formSchema) });

  const { isLoading, isLoggedIn, error } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const onSubmitSignup = (data) => {
    const userData = {
      name: data.name,
      org_id: org.id,
      email: data.email,
      password: data.password,
      phone_number: data.phoneNumber,
      confirmation_flag: data.password === data.confirmPassword,
      activation_method: "OTP",
    };
    dispatch(registerUser(userData))
      .unwrap()
      .then(() => {
        history.push(`/otp?email=${data.email}`);
      });
  };

  useEffect(() => {
    if (isLoggedIn) {
      history.replace("/");
    }

    return () => {
      dispatch(clearError());
    };
  }, [isLoggedIn, history, dispatch]);

  return (
    <PageContainer
      text="Signup"
      backArrow
      page="auth-main"
      mainHeaderText="Create your account"
      subHeaderText={
        <>
          Please fill these information <br /> to create new account
        </>
      }
    >
      <AuthContainer>
        <FormGroup onSubmit={handleSubmit(onSubmitSignup)}>
          <FormControl
            icon={<User />}
            placeholder="Enter your name"
            errorMessage={errors.name?.message}
            register={register("name")}
            label="Full name"
          />
          <FormControl
            icon={<Phone />}
            type="tel"
            inputMode="tel"
            placeholder="Enter your phone number"
            errorMessage={errors.phoneNumber?.message}
            register={register("phoneNumber")}
            label="Phone number"
          />
          <FormControl
            icon={<Sms />}
            inputMode="email"
            placeholder="Enter your email"
            errorMessage={errors.email?.message}
            register={register("email")}
            label="Email address"
          />

          <FormControl
            icon={<Lock />}
            type="password"
            placeholder="Enter your password"
            errorMessage={errors.password?.message}
            register={register("password")}
            label="Password"
          />
          <FormControl
            icon={<Lock />}
            type="password"
            placeholder="Confirm your password"
            errorMessage={errors.confirmPassword?.message}
            register={register("confirmPassword")}
            label="Confirm password"
          />
          {error && (
            <ErrorMessage center>{getErrorMessage(error.error)}</ErrorMessage>
          )}
          <Button loading={isLoading}>Create account</Button>
        </FormGroup>
        <AuthLabelAction route="login">Back to login</AuthLabelAction>
      </AuthContainer>
    </PageContainer>
  );
};

export default SignUp;
