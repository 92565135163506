import { Link } from "react-router-dom";

import People from "../../Icons/People";

import classes from "./MetaverseCard.module.scss";

import { dateOptions, formatAmPm } from "../../../Utils/functions";
import Utils from "../../../Utils/utils";

const MetaverseCard = ({ data, popupCard, greyImage }) => {
  const { id, name, startsAt, endsAt, attachments, interests } = data;
  const eventCover = attachments?.find((i) =>
    i?.type?.includes("event_cover")
  )?.url;

  return (
    <Link
      to={`/metaverse/${id}`}
      className={`${classes.metaverseCard} ${
        popupCard ? classes.popupCard : ""
      }`}
    >
      <div className={`${classes.imgContainer} ${greyImage ? classes.greyImage : ""}`}>
        <img alt={name} src={Utils.getProperImageUrl(eventCover)} />
      </div>
      <div className={classes.content}>
        <h3>{name}</h3>
        <p>
          {new Date(startsAt).toLocaleDateString("en-US", dateOptions)}
          <span className={classes.dot}></span>
          {formatAmPm(new Date(startsAt))} - {formatAmPm(new Date(endsAt))}
        </p>
        <p className={classes.peopleRegistered}>
          <People />
          {interests} people registered
        </p>
      </div>
    </Link>
  );
};

export default MetaverseCard;
