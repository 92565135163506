import { useState, useEffect } from "react";
import { useGetEventsQuery } from "../../../../../Store/Services/events";

import PopupContentHeader from "../PopupContentHeader/PopupContentHeader";
import FallbackEmpty from "../../../../UI/FallbackEmpty/FallbackEmpty";

import Spinner from "../../../../UI/Spinner/Spinner";
import Try from "../../../../Icons/Try";

import Utils from "../../../../../Utils/utils";

import classes from "./Metaverse.module.scss";

const Metaverse = ({ orgId, defaultImage }) => {
    const [events, setEvents] = useState([]);

    const { data, isLoading } = useGetEventsQuery({
        start: 0,
        count: 1,
        orgId,
    });

    useEffect(() => {
        if (data) {
            setEvents(data?.content);
        }
    }, [data]);

    return (
        <div className={classes.metaverse}>
            <PopupContentHeader
                title="Metaverse Events"
                text="Join with available events"
            />

            {isLoading ? (
                <Spinner fullHeight />
            ) : !events?.length ? (
                <FallbackEmpty>No Events Found...</FallbackEmpty>
            ) : (
                <div className={classes.content}>
                    <img
                        alt={events[0]?.name}
                        src={Utils.getProperImageUrl(
                            events?.[0]?.attachments?.find((i) =>
                                i?.type?.includes("event_cover")
                            )?.url
                            || defaultImage) }
                    />
                    <div className={classes.backDrop}></div>
                    <div className={classes.textContainer}>
                        <Try />
                        <p>See all events</p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Metaverse;
